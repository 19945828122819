import React,{ Suspense }  from "react";
import { LinearProgress } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const HomePage = React.lazy(() => import("../Pages/Home"));
const Terms = React.lazy(() => import("../Pages/Terms"));
const About = React.lazy(() => import("../Pages/About"));
const NotFound = React.lazy(() => import("../Pages/NotFound"));

const routes = (
    <Router>
        <Suspense fallback={<LinearProgress />}>
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/terms" element={<Terms />} />
                <Route exact path="/about" element={<About />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    </Router>
);

export default routes;