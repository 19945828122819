import React from "react";
import { ThemeProvider } from "@mui/system";
import routes from './Routes';
import theme from './Theme'
import "./App.css";


function App() {
  return (
    <ThemeProvider theme={theme}>
      {routes}
    </ThemeProvider>
  );
}

export default App;
